/* eslint-disable no-restricted-globals */
import store from '../configureStore';
import { setBasicInfoAddr } from '../modules/user';

/**
 * @wiki 주소검색 팝업 가이드 (https://wiki.11stcorp.com/pages/viewpage.action?pageId=393041026)
 */

// eslint-disable-next-line import/prefer-default-export
export function onClickSearchAddr() {
  const url = 'https://member.11st.co.kr/addr/search?';
  const params = ['dispType=road', 'impossibleAlertCode=01'];
  const popupName = 'popupSearchAddr';
  const width = '550';
  const height = '600';
  let attr = `height=${height},innerHeight=${height}`;
  attr += `,width=${width},innerWidth=${width}`;
  if (window.screen) {
    const ah = window.screen.availHeight - 30;
    const aw = window.screen.availWidth - 10;

    const xc = (aw - width) / 2;
    const yc = (ah - height) / 2;

    attr += `,left=${xc},screenX=${xc}`;
    attr += `,top=${yc},screenY=${yc}`;
  }

  window.open(url + params.join('&'), popupName, attr);

  const onReceiveAddress = ({ data }) => {
    const { buildMngNo, baseAddr, jejuYn, islandYn, areaNo } = data;

    store.dispatch(
      setBasicInfoAddr({
        buildMngNo,
        roadAddrNm: unescape(baseAddr),
        jejuYn,
        islandYn,
        areaNo,
      }),
    );
    window.removeEventListener('message', onReceiveAddress);
  };
  window.addEventListener('message', onReceiveAddress, false);
}
