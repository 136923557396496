/* eslint-disable no-nested-ternary */
import { CheckBox, TextInput, ToastMessage, WriteBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import DatePicker from 'components/common/DatePicker';
import TableHead, { IColumn } from 'components/common/TableHead';
import { isBefore, isPast, isToday } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { openDialog } from 'modules/dialog';
import {
  createPromotionDetail,
  deletePromotionProduct,
  getPromotionItemAsync,
  modifyPromotionDetail,
  postPromotionDetailAsync,
  putPromotionDetailAsync,
  setPromotionDetailEndDate,
  setPromotionDetailStartDate,
  setPromotionDetailSubTitle,
  setPromotionDetailTitle,
  setPromotionProductOrderSeq,
  setPromotionProductSelected,
  setPromotionProductSelectedAll,
} from 'modules/promotion';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import { cutByMaxLength } from 'utils';
import { formatDate } from 'utils/format';
import { isSamePageId, setPageId } from 'utils/log';
import { INPUT_REGEX } from 'utils/validate';

interface IFuncButtonProps {
  size: number | string;
  children: string;
  onClick: () => void;
}

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: '전시순서', width: 'auto' },
    { text: '상품번호', width: 'auto' },
    { text: '상품명', width: 'auto' },
    { text: '상품상태', width: 'auto' },
    { text: '카테고리', width: 'auto' },
  ].map((col, index) => ({ ...col, id: index })),
);

const FuncButton = ({ size, children, onClick }: IFuncButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={`btn-style__1 btn-size__${size}`}
  >
    {children}
  </button>
);

const StartDatePicker = () => {
  const startDate = useSelector(
    (state: RootState) => state.promotion.promotionDetail.startDate,
  );
  const dispatch = useDispatch();

  return (
    <DatePicker
      title="프로모션 시작일"
      placeholder="시작일"
      value={formatDate(startDate)}
      source={startDate}
      minDate={new Date()}
      onChange={(date: Date) => {
        dispatch(setPromotionDetailStartDate(date.toISOString()));
        dispatch(setPromotionDetailEndDate(addMonths(date, 3).toISOString()));
      }}
    />
  );
};

const EndDatePicker = () => {
  const startDate = useSelector(
    (state: RootState) => state.promotion.promotionDetail.startDate,
  );
  const endDate = useSelector(
    (state: RootState) => state.promotion.promotionDetail.endDate,
  );
  const dispatch = useDispatch();

  return (
    <DatePicker
      title="프로모션 종료일"
      placeholder="마지막일"
      value={formatDate(endDate)}
      source={endDate}
      minDate={
        startDate
          ? isBefore(new Date(startDate), new Date())
            ? new Date()
            : new Date(startDate)
          : new Date()
      }
      maxDate={startDate ? addMonths(new Date(startDate), 3) : undefined}
      onChange={(date: Date) => {
        dispatch(setPromotionDetailEndDate(date.toISOString()));
      }}
    />
  );
};

const TitleTextInput = () => {
  const title = useSelector(
    (state: RootState) => state.promotion.promotionDetail.title,
  );
  const show = useSelector((state: RootState) => state.alert.show);
  const dispatch = useDispatch();
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 20) {
        dispatch(setPromotionDetailTitle(e.target.value));
      } else {
        dispatch(setPromotionDetailTitle(cutByMaxLength(e.target.value, 20)));
      }
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  return (
    <TextInput
      width="wide"
      value={title}
      title="프로모션 제목"
      placeholder="한글 12자, 영문 20자 이내로 입력해주세요."
      onChange={handleTitleChange}
    />
  );
};

const SubTitleTextInput = () => {
  const subTitle = useSelector(
    (state: RootState) => state.promotion.promotionDetail.subTitle,
  );
  const show = useSelector((state: RootState) => state.alert.show);
  const dispatch = useDispatch();

  const handleSubTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 24) {
        dispatch(setPromotionDetailSubTitle(e.target.value));
      } else {
        dispatch(
          setPromotionDetailSubTitle(cutByMaxLength(e.target.value, 24)),
        );
      }
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  return (
    <TextInput
      width="wide"
      value={subTitle}
      title="프로모션 소제목"
      placeholder="한글 18자, 영문 24자 이내로 입력해주세요."
      onChange={handleSubTitleChange}
    />
  );
};

const ProductAddButton = () => {
  const dispatch = useDispatch();
  const dataLength = useSelector(
    (state: RootState) =>
      state.promotion.promotionDetail.liveProductList.length,
  );

  const handleClick = () => {
    if (dataLength < 100) {
      dispatch(openDialog('SelectProductsFromPromotionDetail'));
    } else {
      dispatch(openAlert('SAVE_PROMOTION_OVER_100_ITEMS'));
    }
  };

  return (
    <FuncButton size="5" onClick={handleClick}>
      상품 조회
    </FuncButton>
  );
};

const PromotionItemNumberInput = ({
  id,
  orderSeq,
}: {
  id: number;
  orderSeq: number;
}) => {
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.floor(Number(e.target.value));
    if (value > 100) {
      dispatch(openAlert('IS_OVER_100_SPECIAL_ITEM_ORDER'));
      dispatch(setPromotionProductOrderSeq(100, id));
    } else if (value < 0) {
      dispatch(openAlert('IS_NEGATIVE_SPECIAL_ITEM_ORDER'));
      dispatch(setPromotionProductOrderSeq(0, id));
    } else {
      dispatch(setPromotionProductOrderSeq(value, id));
    }
  };

  return (
    <TextInput
      type="number"
      value={orderSeq.toString()}
      title={`전시순서_${id}`}
      width="size__2"
      onChange={handleChange}
    />
  );
};

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const products = useSelector(
    (state: RootState) => state.promotion.promotionDetail.liveProductList,
  );
  const selected = useSelector(
    (state: RootState) => state.promotion.promotionDetail.selected,
  );
  const { title, subTitle, startDate, endDate } = useSelector(
    (state: RootState) => state.promotion.promotionDetail,
  );

  const isUpdate = useMemo(() => !!id, [id]);
  const productsLength = useMemo(
    () => (Array.isArray(products) ? products.length : 0),
    [products],
  );
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  useEffect(() => {
    if (
      !isUpdate &&
      !isSamePageId('/seller_office/store/promotion_registration')
    ) {
      setPageId('/seller_office/store/promotion_registration');
    }
  }, [isUpdate]);

  const onClickDeleteSelected = () => {
    if (selected.length > 0) {
      dispatch(deletePromotionProduct());
    } else {
      dispatch(openAlert('DELETE_PRODUCT_WITH_NO_DATA'));
    }
  };

  useEffect(() => {
    if (id && storeNo) {
      dispatch(getPromotionItemAsync.request({ storeNo, promotionNo: id }));
    }
  }, [dispatch, id, storeNo]);

  return (
    <div className="content-in" id="soContent">
      <div className="view-title title-style__2">
        <h2 className="title">
          {isUpdate ? '프로모션 수정' : '신규 프로모션 생성'}
        </h2>
      </div>
      <div className="view-cont">
        <div className="search-box box-type__bg">
          <WriteBox title="기간" essential>
            <div className="c-date__calender">
              <StartDatePicker />
              <span className="section-bar">~</span>
              <EndDatePicker />
            </div>
            <p className="text_info">
              프로모션 종료일은 시작일로부터 최대 3개월까지 등록 가능합니다.
            </p>
          </WriteBox>
          <WriteBox title="대제목" essential>
            <TitleTextInput />
          </WriteBox>
          <WriteBox title="소제목" essential>
            <SubTitleTextInput />
          </WriteBox>
        </div>
        <div className="cont-filter">
          <div className="filter-side__1">
            <ProductAddButton />
            <span className="text_add">6개 이상 등록해주세요.</span>
          </div>
          {productsLength > 0 && (
            <div className="filter-side__2">
              <FuncButton size="6" onClick={onClickDeleteSelected}>
                선택삭제
              </FuncButton>
            </div>
          )}
        </div>
        <div className="table-wrap">
          <table className="table">
            <caption>상품목록</caption>
            <colgroup>
              <col width="40px" />
              <col width="90px" />
              <col width="110px" />
              <col />
              <col width="90px" />
              <col />
            </colgroup>
            <TableHead columns={COLUMNS}>
              <th scope="col">
                <CheckBox
                  id="checkItem1"
                  name="checkItem"
                  label="전체선택"
                  type={CheckBoxType.basic}
                  checked={
                    productsLength > 0 && productsLength === selected.length
                  }
                  onChange={() => {
                    dispatch(setPromotionProductSelectedAll());
                  }}
                  labelHidden
                />
              </th>
            </TableHead>
            <tbody>
              {productsLength > 0 ? (
                products.map(product => (
                  <tr
                    key={product.productNo}
                    className={
                      selected.includes(product.productNo) ? 'active' : ''
                    }
                  >
                    <td>
                      <CheckBox
                        id={`checkbox_${product.productNo}`}
                        name="checkItem"
                        label="상품번호-1"
                        type={CheckBoxType.basic}
                        checked={selected.includes(product.productNo)}
                        onChange={() => {
                          dispatch(
                            setPromotionProductSelected(product.productNo),
                          );
                        }}
                        labelHidden
                      />
                    </td>
                    <td>
                      <PromotionItemNumberInput
                        id={product.productNo}
                        orderSeq={product.orderSeq}
                      />
                    </td>
                    <td>
                      <span className="text-num">{product.productNo}</span>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="text-title"
                        onClick={() =>
                          window.open(
                            `https://11st.co.kr/product/SellerProductDetail.tmall?method=getSellerProductDetail&prdNo=${product.productNo}`,
                            '_blank',
                          )
                        }
                      >
                        {product.productName}
                      </button>
                    </td>
                    <td>{product.sellStatusTypeDesc ?? '-'}</td>
                    <td className="td-side">
                      {product.sellStatusTypeDesc === '판매중'
                        ? product.displayCategoryName
                        : '-'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="non-data__2">
                    <p>상품을 6개 이상 등록해주세요.</p>
                    <ProductAddButton />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="view-btn__area">
        <button
          type="submit"
          className="btn-style__2 btn-size__2"
          onClick={() => history.push('/promotion')}
        >
          취소
        </button>
        {isUpdate ? (
          <button
            type="submit"
            className="btn-style__3 btn-size__2"
            disabled={productsLength < 6}
            onClick={() => {
              if (id) {
                if (title.length === 0) {
                  dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
                } else if (subTitle.length === 0) {
                  dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
                } else if (startDate.length === 0 || endDate.length === 0) {
                  dispatch(openAlert('SAVE_PROMOTION_WITHOUT_DATE'));
                } else if (
                  !isToday(new Date(endDate)) &&
                  isPast(new Date(endDate))
                ) {
                  dispatch(openAlert('SAVE_PROMOTION_WITH_INVALID_DATE'));
                } else {
                  dispatch(modifyPromotionDetail(id));
                  history.push('/promotion');
                }
              }
            }}
          >
            수정
          </button>
        ) : (
          <button
            type="submit"
            className="btn-style__3 btn-size__2"
            disabled={productsLength < 6}
            onClick={e => {
              if (title.length === 0) {
                dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
              } else if (subTitle.length === 0) {
                dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
              } else if (startDate.length === 0 || endDate.length === 0) {
                dispatch(openAlert('SAVE_PROMOTION_WITHOUT_DATE'));
              } else if (
                !isToday(new Date(endDate)) &&
                isPast(new Date(endDate))
              ) {
                dispatch(openAlert('SAVE_PROMOTION_WITH_INVALID_DATE'));
              } else {
                dispatch(createPromotionDetail());
                history.push('/promotion');
              }
              if (window.rakeLog) {
                window.rakeLog.sendRakeLog(e.target);
              }
            }}
            data-log-actionid-area="promotion"
            data-log-actionid-label="registration"
          >
            등록
          </button>
        )}
      </div>
      <ToastMessage
        action={getType(postPromotionDetailAsync.request)}
        successText="저장되었습니다."
      />
      <ToastMessage
        action={getType(putPromotionDetailAsync.request)}
        successText="저장되었습니다."
      />
    </div>
  );
};

export default Edit;
