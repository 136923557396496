import { PRODUCT_REQUEST, PRODUCT_SORT } from 'apis/product';
import {
  CheckBox,
  Dropdown,
  Radio,
  TextInput,
  WriteBox,
} from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import Pagination from 'components/common/Pagination';
import { RadioType } from 'components/common/Radio';
import TableHead, { IColumn } from 'components/common/TableHead';
import useCheckbox from 'hooks/useCheckBox';
import useTextInput from 'hooks/useTextInput';
import { RootState } from 'modules';
import {
  getBasicLargeCategoryAsync,
  getBasicMiddleCategoryAsync,
  getBasicSmallCategoryAsync,
  IBasicCategoryItem,
} from 'modules/category';
import {
  DISPLAY_PRODUCT_POPUP_PAGE_SIZE,
  getDisplayProductAsync,
  resetFoundProducts,
  setCurrentSelectedProducts,
} from 'modules/product';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getProductCategoryString } from 'utils/format';

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze([
  { id: 1, text: '상품번호', width: 'auto' },
  { id: 2, text: '상품명', width: 'auto' },
  { id: 3, text: '카테고리', width: 'auto' },
]);
const FILTER_OPTIONS = [
  { id: '1', name: '최신등록순', value: PRODUCT_SORT.NEW },
  { id: '2', name: '11번가 랭킹순', value: PRODUCT_SORT.RANKING },
];

const EmptyProduct = () => (
  <tr>
    <td colSpan={4} className="non-data">
      검색 조건에 맞는 결과가 없습니다.
    </td>
  </tr>
);

interface IProductItemProps {
  isSelectProducts: boolean;
  productNo: number;
  productName: string;
  category: string;
  onChangeSelector: () => void;
  isChecked: boolean;
}

const ProductItem = ({
  isSelectProducts = false,
  productNo,
  productName,
  category,
  onChangeSelector,
  isChecked,
}: IProductItemProps) => {
  const Selector = () =>
    isSelectProducts
      ? React.createElement(CheckBox, {
          type: CheckBoxType.basic,
          id: String(productNo),
          name: 'checkItem',
          checked: isChecked,
          onChange: onChangeSelector,
        })
      : React.createElement(Radio, {
          type: RadioType.basic,
          name: 'checkItem',
          value: String(productNo),
          label: `상품번호${productNo}`,
          checked: isChecked,
          onChange: onChangeSelector,
          labelHidden: true,
        });

  return (
    <tr className={isChecked ? 'active' : ''}>
      <td>
        <Selector />
      </td>
      <td>
        <span className="text-num">{productNo}</span>
      </td>
      <td className="td-side">
        <button
          type="button"
          className="text-title"
          onClick={() =>
            window.open(
              `https://11st.co.kr/product/SellerProductDetail.tmall?method=getSellerProductDetail&prdNo=${productNo}`,
              '_blank',
            )
          }
        >
          {productName}
        </button>
      </td>
      <td className="td-side">{category}</td>
    </tr>
  );
};

const mapCategoryToDropdown = (list: IBasicCategoryItem[]) => {
  const newList = list.map(({ categoryNo, categoryName }) => ({
    id: String(categoryNo),
    value: String(categoryNo),
    name: categoryName,
  }));
  if (list.length > 0) {
    newList.unshift({ id: '-1', value: '-1', name: '전체' });
  }

  return newList;
};

const SelectProduct = ({
  isSelectProducts = false,
  productRequestType = PRODUCT_REQUEST.ALL,
}: {
  isSelectProducts: boolean;
  productRequestType?: PRODUCT_REQUEST;
}) => {
  const dispatch = useDispatch();
  const basicLarge = useSelector(
    (state: RootState) => state.category.basicLarge,
  );
  const basicMiddle = useSelector(
    (state: RootState) => state.category.basicMiddle,
  );
  const basicSmall = useSelector(
    (state: RootState) => state.category.basicSmall,
  );
  const foundProductsTotal = useSelector(
    (state: RootState) => state.product.foundProductsTotal,
  );
  const foundProducts = useSelector(
    (state: RootState) => state.product.foundProducts,
  );
  const { encSellerNo, storeNo } = useSelector(
    (state: RootState) => state.user.headerInfo,
    shallowEqual,
  );

  const [productName, onChangeProductName] = useTextInput();
  const [productNumber, onChangeProductNumber] = useTextInput({
    validatorOption: { max: 2000, type: 'productNumbers' },
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [largeCategoryNumber, setLargeCategoryNumber] = useState<number>(-1);
  const [middleCategoryNumber, setMiddleCategoryNumber] = useState<number>(-1);
  const [smallCategoryNumber, setSmallCategoryNumber] = useState<number>(-1);
  const [sortType, setSortType] = useState<string>(PRODUCT_SORT.NEW);

  const largeCategory = useMemo(() => mapCategoryToDropdown(basicLarge), [
    basicLarge,
  ]);
  const middleCategory = useMemo(() => mapCategoryToDropdown(basicMiddle), [
    basicMiddle,
  ]);
  const smallCategory = useMemo(() => mapCategoryToDropdown(basicSmall), [
    basicSmall,
  ]);
  const productsLength = useMemo(() => foundProducts.length, [
    foundProducts.length,
  ]);
  const hasProducts = useMemo(() => productsLength > 0, [productsLength]);

  const [
    isCheckedAll,
    toggleCheckAll,
    isChecked,
    toggleCheckBox,
    checkboxes,
    setCheckboxes,
  ] = useCheckbox(productsLength);

  const onSelectLargeCategory = useCallback(
    (categoryNo: string) => {
      const categoryNumber = Number(categoryNo);
      dispatch(
        getBasicMiddleCategoryAsync.request({
          encSellerNo,
          storeNo,
          largeCategoryNumber: categoryNumber,
        }),
      );
      setLargeCategoryNumber(categoryNumber);
    },
    [dispatch, encSellerNo, storeNo],
  );

  const onSelectMiddleCategory = useCallback(
    (categoryNo: string) => {
      const categoryNumber = Number(categoryNo);
      dispatch(
        getBasicSmallCategoryAsync.request({
          encSellerNo,
          storeNo,
          largeCategoryNumber,
          middleCategoryNumber: categoryNumber,
        }),
      );
      setMiddleCategoryNumber(categoryNumber);
    },
    [dispatch, encSellerNo, largeCategoryNumber, storeNo],
  );

  const onSelectSmallCategory = useCallback((categoryNo: string) => {
    const categoryNumber = Number(categoryNo);
    setSmallCategoryNumber(categoryNumber);
  }, []);

  const onClickFind = useCallback(() => {
    const productNumberList = productNumber.replace(/[ ]/gi, '').split('\n');
    dispatch(
      getDisplayProductAsync.request({
        encSellerNo,
        productNumberList,
        searchKeyword: productName,
        largeCategoryNumber,
        middleCategoryNumber,
        smallCategoryNumber,
        pageSize: DISPLAY_PRODUCT_POPUP_PAGE_SIZE,
        pageNumber: (currentPage - 1) * DISPLAY_PRODUCT_POPUP_PAGE_SIZE,
        sort: sortType as PRODUCT_SORT,
        productRequestType,
        isProductNoFirst: false,
      }),
    );
  }, [
    currentPage,
    dispatch,
    encSellerNo,
    largeCategoryNumber,
    middleCategoryNumber,
    productName,
    productNumber,
    productRequestType,
    smallCategoryNumber,
    sortType,
  ]);

  const onChangeProductListFilter = useCallback(
    (filter: string) => setSortType(filter),
    [],
  );

  const setCurrentSelectProductList = useCallback(() => {
    const currentSelectProducts = foundProducts.filter((_, i) => checkboxes[i]);
    dispatch(setCurrentSelectedProducts(currentSelectProducts));
  }, [checkboxes, dispatch, foundProducts]);

  const onChangeSelector = useCallback(
    (index: number) => {
      if (isSelectProducts) {
        toggleCheckBox(index);
      } else {
        setCheckboxes(prevCheckboxes =>
          prevCheckboxes.map((__, i) => index === i),
        );
      }
    },
    [isSelectProducts, setCheckboxes, toggleCheckBox],
  );

  useEffect(() => {
    setCurrentSelectProductList();
  }, [setCurrentSelectProductList]);

  useEffect(() => {
    dispatch(resetFoundProducts());

    return () => {
      dispatch(resetFoundProducts());
      dispatch(setCurrentSelectedProducts([]));
    };
  }, [dispatch]);

  useEffect(() => {
    setMiddleCategoryNumber(-1);
    setSmallCategoryNumber(-1);
  }, [largeCategoryNumber]);

  useEffect(() => {
    setSmallCategoryNumber(-1);
  }, [middleCategoryNumber]);

  useEffect(() => {
    onClickFind();
    /* eslint-disable */
  }, [currentPage, sortType]);

  useEffect(() => {
    dispatch(
      getBasicLargeCategoryAsync.request({
        encSellerNo,
        storeNo,
      }),
    );
  }, [dispatch, encSellerNo, storeNo]);



  return (
    <div className="dialog-body">
      <div className="search-box">
        <WriteBox title="상품명">
          <TextInput
            width="wide"
            title="상품명"
            placeholder="입력해주세요"
            value={productName}
            onChange={onChangeProductName}
          />
        </WriteBox>
        <WriteBox title="상품번호">
          {isSelectProducts ? (
            <div className="c-textarea wide">
              <textarea
                rows={4}
                cols={100}
                className="textarea"
                placeholder="상품번호 구분은 줄바꿈입니다."
                value={productNumber}
                onChange={onChangeProductNumber}
              />
            </div>
          ) : (
            <TextInput
              width="wide"
              title="상품번호"
              placeholder="입력해주세요"
              value={productNumber}
              onChange={onChangeProductNumber}
            />
          )}
        </WriteBox>
        <WriteBox title="카테고리">
          <div className="cont_flex">
            <Dropdown
              id="listboxButton01"
              options={largeCategory}
              onSelect={onSelectLargeCategory}
              placeholder="선택하세요"
              disabled={!largeCategory.length}
            />
            <Dropdown
              id="listboxButton02"
              options={middleCategory}
              onSelect={onSelectMiddleCategory}
              placeholder="선택하세요"
              initialValue={middleCategory[0]?.value ?? -1}
              disabled={largeCategoryNumber === -1 || !middleCategory.length}
            />
            <Dropdown
              id="listboxButton03"
              options={smallCategory}
              onSelect={onSelectSmallCategory}
              placeholder="선택하세요"
              initialValue={smallCategory[0]?.value ?? -1}
              disabled={middleCategoryNumber === -1 || !smallCategory.length}
            />
          </div>
        </WriteBox>
        <div className="box_btn">
          <button
            type="submit"
            onClick={() => onClickFind()}
            className="btn-style__1 btn-size__4"
          >
            조회
          </button>
        </div>
      </div>
      <div className="dialog-body__title">
        <h4>상품목록</h4>
        <div className="side">
          <Dropdown
            width="size__2"
            id="listboxButton03"
            options={FILTER_OPTIONS}
            initialValue={PRODUCT_SORT.NEW}
            placeholder="선택하세요"
            onSelect={onChangeProductListFilter}
          />
        </div>
      </div>

      <div className="table-wrap">
        <table className="table">
          <caption>상품목록</caption>
          <colgroup>
              <col width="42px" />
              <col width="100px" />
              <col />
              <col />
          </colgroup>
          <TableHead columns={COLUMNS}>
            <th scope="col">
              {isSelectProducts && (
                <CheckBox
                  id="checkItem0"
                  name="checkItem"
                  type={CheckBoxType.basic}
                  checked={isCheckedAll}
                  onChange={toggleCheckAll}
                />
              )}
            </th>
          </TableHead>
          <tbody>
            {hasProducts ? (
              foundProducts.map(
                (
                  {
                    productNo,
                    productName: pName,
                    displayCategoryName1,
                    displayCategoryName2,
                    displayCategoryName3,
                    displayCategoryName4,
                  },
                  index,
                ) => {
                  const productCategory = getProductCategoryString(
                    displayCategoryName1,
                    displayCategoryName2,
                    displayCategoryName3,
                    displayCategoryName4,
                  );

                  return (
                    <ProductItem
                      key={productNo}
                      isSelectProducts={isSelectProducts}
                      productNo={productNo}
                      productName={pName}
                      category={productCategory}
                      onChangeSelector={() => onChangeSelector(index)}
                      isChecked={isChecked(index)}
                    />
                  );
                },
              )
            ) : (
              <EmptyProduct />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        total={foundProductsTotal}
        pageSize={DISPLAY_PRODUCT_POPUP_PAGE_SIZE}
      />
    </div>
  );
};

export default SelectProduct;
